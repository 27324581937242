<template>
  <div class=container v-if="a && ap && t">
      <div class=search>
          <div class=swraper>
      <span> Areas&nbsp;</span>
      <select class=select v-model="selectedArea" @change="newarea();refresh()" >
          <optgroup>
          <option class=choosen v-for="(value,key) in area" :key="key">{{key}}</option>
          </optgroup>
      </select>
        <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Topics&nbsp;</span>
        <select class=select v-model="selectedTopic" @change="refresh()" >
          <optgroup>
          <option class=choosen v-for="value in area[selectedArea]" :key="value">{{value}}</option>
          </optgroup>
      </select>
        </div>
      </div>
      <div class=result>
        <div v-for="[key, value] in selectedPapers" :key="key">
            <p class=year>{{ key }}</p> 
            <div v-for="[newkey,newvalue] in value" :key="newkey">
                    <p class=type >{{newkey}}</p>
                    <div class=publication v-for="paper in newvalue" :key="paper">
                        <b>"{{papers[paper].title}}"<br> </b>
                        <span v-for="line in papers[paper].rest" :key="line">{{line}}<br> </span>
                        [<a class=url :href="papers[paper].url" target="new">PDF</a>] 
                        <router-link class=url :to="{path:'/reference',query:{bid:paper}}">[bib]</router-link>
                        <router-link class=url  :to="'/copyright'">[&copy;]</router-link>
                        <span v-if="papers[paper].projectPage" >
                        <a v-if="papers[paper].projectPage[0].special" class=url :href="papers[paper].projectPage[0].url">[Project Page] </a>
                        <router-link class=url  v-else :to="{path:'/projects/categories/project',
                        query: {cid:papers[paper].projectPage[0].cate, pid:papers[paper].projectPage[0].proj}}">
                        [Project Page]</router-link>
                        
                        
                        </span>
                        <br>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
// import Areas from "../assets/publications/Areas.json"
// import Topics from "../assets/publications/Topics.json"
// import Papers from '../assets/publications/APA.json'
import axios from "axios"
export default {
    data(){
        return{
            area:this.area,
            topics:this.topics,
            papers:this.papers,
            selectedArea:"ALL",
            selectedTopic:"ALL",
            selectedPapers: new Set(),
            a:this.a,
            ap:this.ap,
            t:this.t
        }
    },
    created(){

        // axios.get("http://localhost:8080/api/Statics/publications/APA.json").then((res)=>{this.papers=res.data 
        // this.ap = true}).then(axios.get("http://localhost:8080/api/Statics/publications/Topics.json").then((res)=>{this.topics=res.data 
        // this.t = true})).then(axios.get("http://localhost:8080/api/Statics/publications/Areas.json").then((res)=>{this.area=res.data 
        // this.a = true})).finally(this.initialize)

    
        axios.get("https://cave.cs.columbia.edu/Statics/publications/APA.json").then((res)=>{this.papers=res.data 
        this.ap = true}).then(axios.get("https://cave.cs.columbia.edu/Statics/publications/Topics.json").then((res)=>{this.topics=res.data 
        this.t = true})).then(axios.get("https://cave.cs.columbia.edu/Statics/publications/Areas.json").then((res)=>{this.area=res.data 
        this.a = true})).finally(this.initialize)
        },
    methods:{
        initialize(){
               var result = new Map()
                for (let i = Object.keys(this.papers).length;i>0;i--){
                var object = this.papers[i]
                var year = object.year
                var tt = object.type
                if (result.get(year) ==undefined){
                    var a = new Map()
                    a.set(tt,[i])
                    result.set(year, a)
                }
                else{
                    var inner = result.get(year)
                    if (!inner.has(tt)){
                        inner.set(tt,[i])
                    }
                    else{
                        inner.get(tt).push(i)
                        
                    }
                }
            }
            this.selectedPapers = result
        },

        newarea(){
          this.selectedTopic='ALL'  
        },
        refresh(){
            var temp = new Set()
            if (this.selectedTopic == "ALL"){
            for (const topic of this.area[this.selectedArea]){
                if (topic !="ALL"){
                    for (const paper of this.topics[topic]){
                         temp.add(paper)
                        }
                    }
                }
            }
            else{
                for (const paper of this.topics[this.selectedTopic]){
                    temp.add(paper)
                }
            }
            var temparray = Array.from(temp).sort((a,b)=>(a-b)).reverse()
            var result = new Map()
            for (var t in temparray){
                var object = this.papers[temparray[t]]
                var year = object.year
                var tt = object.type
                if (result.get(year) ==undefined){
                    var a = new Map()
                    a.set(tt,[temparray[t]])
                    result.set(year, a)
                }
                else{
                    var inner = result.get(year)
                    if (!inner.has(tt)){
                        inner.set(tt,[temparray[t]])
                    }
                    else{
                        inner.get(tt).push(temparray[t])
                        
                    }
                }
            }
            this.selectedPapers = result
        }
    }

}
</script>

<style scoped>
.container{
    /* letter-spacing: .05em; */
    line-height:1.4em;
    font-size: 18px;
    align-content: center;
    min-height: 100vh;
    width:1400px;
    margin:0 auto;
}
.result{
    padding-top:1rem;
    font-size: 18px;
    width: 800px;
    margin:0 auto;
    
}
.year{
    /* font-family: 'Roboto', sans-serif; */
    font-size: 32px;
    text-align:left;
    font-weight: bold;

}
.type{
    text-align:left;
    font-weight: bold;
    font-size: 20px;
}
.publication{
    margin-top:1.6rem;
    text-align: left;
}
.url{
    text-decoration: none;
    color: #000;
} 
.select{
    height: 30px;
    width: 280px;
    font-size: 16px;
}
.search{
    width: fit-content;
    margin:0 auto;
}
</style>
